require('../datatable.js')

$(document).on("turbolinks:load", function() {
  $('#orders__search-wrap').text("");
  $('#orders__pagination-wrap').text("");
  $('.orders-container').datatable({
    search: true,
    searchSelector: '#orders__search-wrap',
    namePlural: $('.orders-container').attr("plural"),
    nameSingular: $('.orders-container').attr("singular"),
    searchPlaceholder: "Search",
    pagingSelector: '#orders__pagination-wrap',
    elementSelector: 'tr.order',
    rootnode: '.orders-wrap',
    isTable: false,
    elementsPerPage: 50
  });
});
